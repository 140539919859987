import React, {useState , useEffect } from "react";
import Sidebar from "../layouts/Sidebar";
import { ChildrenType } from "../types";
import Header from "../layouts/Header";
import { Outlet } from "react-router-dom";
import { useDispatch , useSelector } from "react-redux";
import { getMethodService } from "../services";
import { AUTH_INFO_API } from "../services/constants";
import { useQuery } from "@tanstack/react-query";
import { stateObjType } from '../types';
import { userHandler } from '../store/slices/userInfoSlice';

const Template = () => {

  const dispatch = useDispatch();
  const {value} = useSelector((state:any) => state.unitController);
  const fetchUserInfo = () => getMethodService(AUTH_INFO_API);
  const userInfoQuery = useQuery({ queryKey: ['userinfo', value], queryFn: fetchUserInfo,refetchOnWindowFocus: false});

  useEffect(()=>{
    if(userInfoQuery){
        dispatch(userHandler(userInfoQuery?.data?.data));
    };
  },[userInfoQuery]);

  return (
    <div>
      <Header />
      <Sidebar />
      <main id="content" role="main" className="main">
        <div className="content container-fluid">
          <div className="pb-3 mb-3 page-header">
            <div className="row align-items-center">
              <div className="col">
                {/* <h1 className="page-header-title"></h1> */}
              </div>
            </div>
          </div>
          <Outlet />
        </div>
      </main>
    </div>
  );
};

export default Template;
