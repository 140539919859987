import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes, useNavigate } from "react-router-dom";
import Login from "../auths/Login";
import Dashboard from "../components/Dashboard";
import ChangePassword from "../components/ChangePassword";

import Protect from "./Protect";
import Setting from "../components/Setting";
import Users from "../components/Users";
import WalletTrans from "../components/WalletTrans";
import Caption from "../components/Caption";
import Result from "../components/Result";
import CreateSenior from "../components/CreateSenior";
import Winner from "../components/Winner";
import Report from "../components/Report";
import ProfitLoss from "../components/ProfitLoss";
import Bets from "../components/Bets";
import { AUTHENTICATED_ROUTE } from "../contants/routes";
import Template from "../utils/Template";
import { decodeData, HandleLogout } from "../utils/Helper";
import { getMethodService } from "../services";
import { useQuery } from "@tanstack/react-query";
import { AUTH_INFO_API } from "../services/constants";
import { useDispatch, useSelector } from "react-redux";
import { userHandler } from '../store/slices/userInfoSlice';
import { stateObjType } from '../types';

const Routers = () => {

  return (
    <Router basename="/">
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Template/>}>
          {AUTHENTICATED_ROUTE.map((r) => {
            const Component = r.component;
            const path = r.path;
            return (
              <Route
                key={path}
                path={path}
                element={
                  <Protect>
                    <Component  {...r.props} />
                  </Protect>
                }
              />
            );
          })}
        </Route>
        {/* <Route path="/change-password" element={<Protect><ChangePassword /></Protect>} /> 
        <Route path="/" element={<Protect><Dashboard /></Protect>} /> 
        <Route path="/caption" element={<Protect><Caption /></Protect>} /> 
        <Route path="/setting" element={<Protect><Setting /></Protect>} /> 

        <Route path="/users/senior" element={<Protect><Users title="Seniors" type="seniors" endPoint="seniors-paginate" /></Protect>} /> 
        <Route path="/users/master" element={<Protect><Users title="Masters" type="masters" endPoint="masters-paginate" /></Protect>} /> 
        <Route path="/users/agent" element={<Protect><Users title="Agents" type="agents" endPoint="agents-paginate" /></Protect>} /> 
        <Route path="/users/user" element={<Protect><Users title="Users" type="users" endPoint="users-paginate" /></Protect>} /> 
        <Route path="/users/create-senior" element={<Protect><CreateSenior/></Protect>} /> 

        <Route path="/wallet-trans/seniors-trans" element={<Protect><WalletTrans title="Seniors Transaction" endPoint="/admin-senior-transactions" /></Protect>} /> 
        <Route path="/wallet-trans/masters-trans" element={<Protect><WalletTrans title="Masters Transaction" endPoint="/admin-master-transactions" /></Protect>} /> 
        <Route path="/wallet-trans/agents-trans" element={<Protect><WalletTrans title="Agents Transaction" endPoint="/admin-agent-transactions" /></Protect>} /> 
        <Route path="/wallet-trans/users-trans" element={<Protect><WalletTrans title="Users Transaction" endPoint="/admin-user-transactions" /></Protect>} /> 

        <Route path="/result/2d" element={<Protect><Result type="MM 2D" resultType="MM" getEndPoint="/two-lucky-number" addEndPoint="/two-winner-create" editEndPoint="/two-winner-win-lose" /></Protect>} /> 
        <Route path="/result/gold2d" element={<Protect><Result type="Gold 2D" resultType="GL" getEndPoint="/two-lucky-number" addEndPoint="/two-winner-create" editEndPoint="/two-winner-win-lose" /></Protect>} /> 
        <Route path="/result/dubai2d" element={<Protect><Result type="Dubai 2D" resultType="DB" getEndPoint="/two-lucky-number" addEndPoint="/two-winner-create" editEndPoint="/two-winner-win-lose" /></Protect>} /> 
        <Route path="/result/malay2d" element={<Protect><Result type="Malay 2D" resultType="ML" getEndPoint="/two-lucky-number" addEndPoint="/two-winner-create" editEndPoint="/two-winner-win-lose" /></Protect>} /> 
        <Route path="/result/3d" element={<Protect><Result type="MM 3D" resultType="MM" getEndPoint="/three-lucky-number" addEndPoint="/three-winner-create" editEndPoint="/three-winner-win-lose" /></Protect>} /> 
        <Route path="/result/dubai3d" element={<Protect><Result type="Dubai 3D" resultType="DB" getEndPoint="/three-lucky-number" addEndPoint="/three-winner-create" editEndPoint="/three-winner-win-lose" /></Protect>} /> 

        <Route path="/winner/2d" element={<Protect><Winner type="MM 2D" winnerType="MM" endPoint="/two-winner-lists"/></Protect>} /> 
        <Route path="/winner/gold2d" element={<Protect><Winner type="Gold 2D" winnerType="GL" endPoint="/two-winner-lists"/></Protect>} /> 
        <Route path="/winner/dubai2d" element={<Protect><Winner type="Dubai 2D" winnerType="DB" endPoint="/two-winner-lists"/></Protect>} /> 
        <Route path="/winner/malay2d" element={<Protect><Winner type="Malay 2D" winnerType="ML" endPoint="/two-winner-lists"/></Protect>} /> 
        <Route path="/winner/3d" element={<Protect><Winner type="MM 3D" winnerType="MM" endPoint="/three-winner-lists"/></Protect>} /> 
        <Route path="/winner/dubai3d" element={<Protect><Winner type="Dubai 3D" winnerType="DB" endPoint="/three-winner-lists"/></Protect>} /> 

        <Route path="/report/2d" element={<Protect><Report type="MM 2D" reportType="MM" endPoint="/two-user-count-sum-amount"/></Protect>} /> 
        <Route path="/report/gold2d" element={<Protect><Report type="Gold 2D" reportType="GL" endPoint="/two-user-count-sum-amount"/></Protect>} /> 
        <Route path="/report/dubai2d" element={<Protect><Report type="Dubai 2D" reportType="DB" endPoint="/two-user-count-sum-amount"/></Protect>} /> 
        <Route path="/report/malay2d" element={<Protect><Report type="Malay 2D" reportType="ML" endPoint="/two-user-count-sum-amount"/></Protect>} /> 
        <Route path="/report/3d" element={<Protect><Report type="MM 3D" reportType="MM" endPoint="/three-user-count-sum-amount"/></Protect>} /> 
        <Route path="/report/dubai3d" element={<Protect><Report type="Dubai 3D" reportType="DB" endPoint="/three-user-count-sum-amount"/></Protect>} /> 

        <Route path="/profit/senior" element={<Protect><ProfitLoss/></Protect>} /> 

        <Route path="/two_lucky_draws/2d" element={<Protect><Bets type="MM 2D" betType="MM" endPoint="/user-betting-history"/></Protect>} /> 
        <Route path="/two_lucky_draws/gold2d" element={<Protect><Bets type="Gold 2D" betType="GL" endPoint="/user-betting-history"/></Protect>} /> 
        <Route path="/two_lucky_draws/dubai2d" element={<Protect><Bets type="Dubai 2D" betType="DB" endPoint="/user-betting-history"/></Protect>} /> 
        <Route path="/two_lucky_draws/malay2d" element={<Protect><Bets type="Malay 2D" betType="ML" endPoint="/user-betting-history"/></Protect>} /> 
        <Route path="/three_lucky_draws/3d" element={<Protect><Bets type="MM 3D" betType="MM" endPoint="/three-user-betting-history"/></Protect>} /> 
        <Route path="/three_lucky_draws/dubai3d" element={<Protect><Bets type="Dubai 3D" betType="DB" endPoint="/three-user-betting-history"/></Protect>} />  */}
      </Routes>
    </Router>
  );
};

export default Routers;
