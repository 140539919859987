import React, { useEffect, useState, useCallback } from "react";
import Template from "../utils/Template";
import { ToastContainer } from "react-toastify";
import { getMethodService, postMethodService } from "../services";
import { addDays, format, subDays } from "date-fns";
import type { RangeKeyDict } from "react-date-range";

import {
  BET_LISTS_API,
  CHANGE_PASSWORD_API,
  LOGIN_API,
  WINNER_API,
} from "../services/constants";
import { useNavigate } from "react-router-dom";
import { HandleLogout } from "../utils/Helper";
import TableScroll from "../utils/TableScroll";
import TableLoading from "../utils/TableLoading";
import { useQuery } from "@tanstack/react-query";
import { stateObjType } from "../types";
import { DateRangePickerComponent} from "../utils/DateRange";
import { momentDateFormat, betType as BetTypeHelper } from "../services/helper";
import type { Range } from "react-date-range";

const Bets = ({ type, betType, endPoint }: any) => {
  const [isLoading, setIsLoading] = useState(false);
  const [bets, setBets] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();
  const [startDate , setStartDate] = useState(null);
  const [endDate , setEndDate] = useState(null);

  const fetchBets = () =>
    getMethodService(`${BET_LISTS_API}${endPoint}?type=${betType}&start_date=${startDate}&end_date=${endDate}`);
  const betsQuery = useQuery({
    queryKey: [type, refreshKey, startDate, endDate],
    queryFn: fetchBets,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (betsQuery) {
      setIsLoading(betsQuery.isLoading);
      if (
        betsQuery?.data === "Unauthenticated." ||
        betsQuery?.data === "E_UNAUTHORIZED_ACCESS: Unauthorized access"
      ) {
        HandleLogout(navigate);
      }
      if (betsQuery?.data?.status === "success") {
        setBets(betsQuery?.data?.data);
      }
    }
  }, [betsQuery]);



  const handleChangeValueDateRangePicker = useCallback(
    (start : any, end : any, label : any) => {
      console.log(label);
      setStartDate(start.format('YYYY-MM-DD'))
      setEndDate(end.format('YYYY-MM-DD'))

    },
    []
  );

  return (
    <>
      
        <div className="card mb-3 mb-lg-5">
          <div className="card-header">
            
            <div className="row align-items-center">
              <div className="col-sm-5">
                <h4 className="card-title">Bet Lists</h4>
              </div>
              <div className="col-sm-7 d-flex justify-content-end">
                <DateRangePickerComponent startDate={startDate} endDate={endDate} onChange={handleChangeValueDateRangePicker}/>
              </div>
            </div>
          </div>
          <div className="card-body">
            <div className="row">
              <div className="col-sm-12">
                <TableScroll>
                  <div>
                    <table className="table text-white w-100 table-bordered table-striped">
                      <thead>
                        <tr style={{ backgroundColor: "rgb(70 70 227)" }}>
                          <th className="p-2 text-center text-light">No</th>
                          <th className="p-2 text-center text-light">User</th>
                          <th className="p-2 text-center text-light">
                            {type === "MM 3D" || type === "Dubai 3D" || type === "Gold 3D"
                              ? "3D"
                              : "2D"}
                          </th>
                          <th className="p-2 text-center text-light">Type</th>
                          <th className="p-2 text-center text-light">Time</th>
                          <th className="p-2 text-center text-light">Amount</th>
                          <th className="p-2 text-center text-light">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {isLoading && <TableLoading col={7} />}
                        {bets.length > 0 ? (
                          bets.map((res: stateObjType, i: number) => (
                            <tr
                              style={{ verticalAlign: "middle" }}
                              key={res?.id}
                            >
                              <td className="p-2 text-center text-dark">
                                {i + 1}
                              </td>
                              <td className="p-2 text-center text-dark">
                                {res?.username}
                              </td>
                              <td className="p-2 text-center text-dark">
                                {res?.number}
                              </td>
                              <td className="p-2 text-center text-dark">
                                {BetTypeHelper(res?.type)}
                              </td>
                              <td className="p-2 text-center text-dark">
                                {type === "MM 2D" && (
                                  <>
                                    {res?.time === "1" && (
                                      <span className="badge bg-info">
                                        12:01 PM
                                      </span>
                                    )}
                                    {res?.time === "2" && (
                                      <span className="badge bg-danger">
                                        04:30 PM
                                      </span>
                                    )}
                                  </>
                                )}
                                {type === "MM 3D" && (
                                  <>
                                    {res?.time === "1" && (
                                      <span className="badge bg-info">
                                        ၁၆ ရက်
                                      </span>
                                    )}
                                    {res?.time === "2" && (
                                      <span className="badge bg-danger">
                                        ၁ ရက်
                                      </span>
                                    )}
                                  </>
                                )}
                                {type === "Malay 2D" && (
                                  <>
                                    {res?.time === "1" && (
                                      <span className="badge bg-info">
                                        10:00 AM
                                      </span>
                                    )}
                                    {res?.time === "2" && (
                                      <span className="badge bg-danger">
                                        12:00 PM
                                      </span>
                                    )}
                                    {res?.time === "3" && (
                                      <span className="badge bg-primary">
                                        02:00 PM
                                      </span>
                                    )}
                                    {res?.time === "4" && (
                                      <span className="badge bg-warning">
                                        04:00 PM
                                      </span>
                                    )}
                                    {res?.time === "5" && (
                                      <span className="badge bg-secondary">
                                        06:00 PM
                                      </span>
                                    )}
                                    {res?.time === "6" && (
                                      <span className="badge bg-success">
                                        08:00 PM
                                      </span>
                                    )}
                                  </>
                                )}
                                {type === "Dubai 2D" && (
                                  <>
                                    {res?.time === "1" && (
                                      <span className="badge bg-info">
                                        11:00 AM
                                      </span>
                                    )}
                                    {res?.time === "2" && (
                                      <span className="badge bg-danger">
                                        01:00 PM
                                      </span>
                                    )}
                                    {res?.time === "3" && (
                                      <span className="badge bg-primary">
                                        03:00 PM
                                      </span>
                                    )}
                                    {res?.time === "4" && (
                                      <span className="badge bg-warning">
                                        05:00 PM
                                      </span>
                                    )}
                                    {res?.time === "5" && (
                                      <span className="badge bg-secondary">
                                        07:00 PM
                                      </span>
                                    )}
                                    {res?.time === "6" && (
                                      <span className="badge bg-success">
                                        09:00 PM
                                      </span>
                                    )}
                                  </>
                                )}
                                {(type === "Gold 2D" || type === "BTC 2D") && (
                                  <>
                                    {res?.time === "1" && (
                                      <span className="badge bg-info">
                                        09:30 AM
                                      </span>
                                    )}
                                    {res?.time === "2" && (
                                      <span className="badge bg-danger">
                                        12:00 PM
                                      </span>
                                    )}
                                    {res?.time === "3" && (
                                      <span className="badge bg-primary">
                                        02:00 PM
                                      </span>
                                    )}
                                    {res?.time === "4" && (
                                      <span className="badge bg-warning">
                                        04:30 PM
                                      </span>
                                    )}
                                    {res?.time === "5" && (
                                      <span className="badge bg-success">
                                        08:00 PM
                                      </span>
                                    )}
                                  </>
                                )}
                                {type === "Dubai 3D" && (
                                  <>
                                    {res?.time === "1" && (
                                      <span className="badge bg-info">
                                        09:00 PM
                                      </span>
                                    )}
                                  </>
                                )}
                              </td>
                              <td className="p-2 text-end text-dark">
                                {res?.amount}
                              </td>
                              <td className="p-2 text-center text-dark">
                                {momentDateFormat(res?.updated_at)}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td className="text-center" colSpan={7}>
                              No found data
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </TableScroll>
              </div>
            </div>
          </div>
        </div>
      
      <ToastContainer />
    </>
  );
};

export default Bets;
