import React, { useEffect, useState } from "react";
import Template from "../utils/Template";
import { ToastContainer } from "react-toastify";
import { postMethodService } from "../services";
import { useNavigate } from "react-router-dom";
import { HandleLogout } from "../utils/Helper";
import CusInput from "../utils/CusInput";
import { CREATE_ADMIN_API, CREATE_SENIOR_API } from "../services/constants";

const CreateAdmin = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [namely, setNamely] = useState<any>("");
  const [username, setUsername] = useState<any>("");
  const [password, setPassword] = useState<any>("");
  const [conPass, setConPass] = useState<any>("");
  const [amount, setAmount] = useState<any>("");

  const [passwordError, setPasswordError] = useState("");
  const [conPasswordError, setConPasswordError] = useState("");
  const navigate = useNavigate();

  const passwordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(e.target.value);
    setPasswordError("");
  };

  const conPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setConPass(e.target.value);
    setConPasswordError("");
  };

  const createSubmitHandler = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const data = {
      name: namely,
      username,
      password,
      password_confirmation: conPass,
      amount,
    };

    postMethodService(CREATE_ADMIN_API, data, true).then((res) => {
      setIsLoading(false);
      if (
        res === "Unauthenticated." ||
        res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"
      ) {
        HandleLogout(navigate);
      }
      if (res?.status === "success") {
        window.location.reload();
        navigate("/admins");
      }
    });
  };

  return (
    <>
      <div className="card mb-3 mb-lg-5">
        <div className="card-header">
          <div className="row align-items-center">
            <div className="col-sm-5">
              <h4 className="card-title mb-2 mb-sm-0">Create Admin</h4>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-sm-12">
              <form onSubmit={createSubmitHandler}>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="namely">
                        Name
                      </label>
                      <input
                        type="text"
                        id="namely"
                        className="form-control"
                        placeholder="Name"
                        value={namely}
                        onChange={(e) => setNamely(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="username">
                        Username
                      </label>
                      <input
                        type="text"
                        id="username"
                        className="form-control"
                        placeholder="Username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <CusInput
                        title="Password"
                        type="password"
                        error={passwordError}
                        tabIndex={1}
                        placeholder="********"
                        name="newPassowrd"
                        value={password}
                        event={passwordHandler}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <CusInput
                        title="Confirm password"
                        type="password"
                        error={conPasswordError}
                        tabIndex={2}
                        placeholder="********"
                        name="conPassowrd"
                        value={conPass}
                        event={conPasswordHandler}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="mb-3">
                      <label className="form-label" htmlFor="amount">
                        Amount
                      </label>
                      <input
                        type="number"
                        id="amount"
                        className="form-control"
                        placeholder="Amount"
                        value={amount}
                        onChange={(e) => setAmount(e.target.value)}
                      />
                    </div>
                  </div>

                  <div className="col-12">
                    <button type="submit" className="btn btn-primary float-end">
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
};

export default CreateAdmin;
