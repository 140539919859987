import moment from "moment"


export const momentTimeFormat = (time) => {
    return moment(time, ["HH:mm:ss"]).format("hh:mm:ss A") ?? 'Invalid Time'
}

export const momentDateFormat = (date) => {
    return moment(date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD h:mm:ss A') ?? 'Invalid Date';
}

export const betType = (type) => {
    switch (type) {
        case "MM":
            return "မြန်မာ";
        case "ML":
            return "မလေး"
        case "DB":
            return "ဒူဘိုင်း"
        case "GL":
            return "ဂိုးလ်"
        case "BTC":
            return "BTC"

        default:
            return "Unknown"
    }

}

export const settingType = (type) => {
    switch (type) {
        case "MM Three":
            return "မြန်မာ 3D";
        case "DB Three":
            return "ဒူဘိုင်း 3D"
        case "MM Two":
            return "မြန်မာ 2D"
        case "ML Two":
            return "မလေး 2D"
        case "DB Two":
            return "ဒူဘိုင်း 2D"
        case "GL Two":
            return "ဂိုးလ် 2D"
        case "GL Three":
            return "ဂိုးလ် 3D"
        case "BTC Two":
            return "BTC 2D"

        default:
            return "Unknown"
    }

}
