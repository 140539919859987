import Bets from "../components/Bets";
import Caption from "../components/Caption";
import ChangePassword from "../components/ChangePassword";
import CreateAdmin from "../components/CreateAdmin";
import CreateSenior from "../components/CreateSenior";
import Dashboard from "../components/Dashboard";
import ProfitLoss from "../components/ProfitLoss";
import Report from "../components/Report";
import Result from "../components/Result";
import Setting from "../components/Setting";
import SuperAdmin from "../components/SuperAdmin";
import Users from "../components/Users";
import WalletTrans from "../components/WalletTrans";
import Winner from "../components/Winner";

export const AUTH_ROUTE = [{
    
}];

export const AUTHENTICATED_ROUTE = [
  {
    path: "change-password",
    component: ChangePassword,
    props: {},
    module : "စကားဝှက်ပြောင်း"
  },
  {
    path: "",
    component: Dashboard,
    props: {},
    module : "ပင်မစာမျက်နှာ"
  },
  
  {
    path: "/admins",
    component: Users,
    props: {
      title: "Admins",
      type: "admins",
      endPoint: "seniors-paginate",
    },
    module: "App Admin များ"
  },
  {
    path: "users/create-admin",
    component: CreateAdmin,
    props: {
      title: "Users",
      type: "users",
      endPoint: "users-paginate",
    },
    module: "App Admin များ"
  },
  {
    path: "caption",
    component: Caption,
    props: {},
    module : "ကြော်ငြာစာသား"
  },
  {
    path: "setting",
    component: Setting,
    props: {},
    module : "ဆက်တင်များ"
  },

  {
    path: "users/senior",
    component: Users,
    props: {
      title: "Seniors",
      type: "seniors",
      endPoint: "seniors-paginate",
    },
    module: "မိတ်ဖက်များ"
  },
  {
    path: "users/master",
    component: Users,
    props: {
      title: "Masters",
      type: "masters",
      endPoint: "masters-paginate",
    },
    module: "မိတ်ဖက်များ"
  },
  {
    path: "users/agent",
    component: Users,
    props: {
      title: "Agents",
      type: "agents",
      endPoint: "agents-paginate",
    },
    module: "မိတ်ဖက်များ"
  },
  {
    path: "users/user",
    component: Users,
    props: {
      title: "Users",
      type: "users",
      endPoint: "users-paginate",
    },
    module: "မိတ်ဖက်များ"
  },
  {
    path: "users/create-senior",
    component: CreateSenior,
    props: {
      title: "Users",
      type: "users",
      endPoint: "users-paginate",
    },
    module: "မိတ်ဖက်များ"
  },

  {
    path: "wallet-trans/seniors-trans",
    component: WalletTrans,
    props: {
      title: "Seniors Transaction",
      endPoint: "/admin-senior-transactions",
    },
    module: "ထည့်ထုတ်မှတ်တမ်း"
  },
  {
    path: "wallet-trans/masters-trans",
    component: WalletTrans,
    props: {
      title: "Masters Transaction",
      endPoint: "/admin-master-transactions",
    },
    module: "ထည့်ထုတ်မှတ်တမ်း"
  },
  {
    path: "wallet-trans/agents-trans",
    component: WalletTrans,
    props: {
      title: "Agents Transaction",
      endPoint: "/admin-agent-transactions",
    },
    module: "ထည့်ထုတ်မှတ်တမ်း"
  },
  {
    path: "wallet-trans/users-trans",
    component: WalletTrans,
    props: {
      title: "Users Transaction",
      endPoint: "/admin-user-transactions",
    },
    module: "ထည့်ထုတ်မှတ်တမ်း"
  },

  {
    path: "result/2d",
    component: Result,
    props: {
      type: "MM 2D",
      resultType: "MM",
      getEndPoint: "/two-lucky-number",
      addEndPoint: "/two-winner-create",
      editEndPoint: "/two-winner-win-lose",
    },
    module: "ထွက်ဂဏာန်းများ"
  },
  {
    path: "result/gold2d",
    component: Result,
    props: {
      type: "Gold 2D",
      resultType: "GL",
      getEndPoint: "/two-lucky-number",
      addEndPoint: "/two-winner-create",
      editEndPoint: "/two-winner-win-lose",
    },
    module: "ထွက်ဂဏာန်းများ"
  },
  {
    path: "result/dubai2d",
    component: Result,
    props: {
      type: "Dubai 2D",
      resultType: "DB",
      getEndPoint: "/two-lucky-number",
      addEndPoint: "/two-winner-create",
      editEndPoint: "/two-winner-win-lose",
    },
    module: "ထွက်ဂဏာန်းများ"
  },
  {
    path: "result/malay2d",
    component: Result,
    props: {
      type: "Malay 2D",
      resultType: "ML",
      getEndPoint: "/two-lucky-number",
      addEndPoint: "/two-winner-create",
      editEndPoint: "/two-winner-win-lose",
    },
    module: "ထွက်ဂဏာန်းများ"
  },
  {
    path: "result/3d",
    component: Result,
    props: {
      type: "MM 3D",
      resultType: "MM",
      getEndPoint: "/three-lucky-number",
      addEndPoint: "/three-winner-create",
      editEndPoint: "/three-winner-win-lose",
    },
    module: "ထွက်ဂဏာန်းများ"
  },
  {
    path: "result/dubai3d",
    component: Result,
    props: {
      type: "Dubai 3D",
      resultType: "DB",
      getEndPoint: "/three-lucky-number",
      addEndPoint: "/three-winner-create",
      editEndPoint: "/three-winner-win-lose",
    },
    module: "ထွက်ဂဏာန်းများ"
  },
  {
    path: "result/btc2d",
    component: Result,
    props: {
      type: "BTC 2D",
      resultType: "BTC",
      getEndPoint: "/two-lucky-number",
      addEndPoint: "/two-winner-create",
      editEndPoint: "/two-winner-win-lose",
    },
    module: "ထွက်ဂဏာန်းများ"
  },

  {
    path: "result/gold3d",
    component: Result,
    props: {
      type: "Gold 3D",
      resultType: "GL",
      getEndPoint: "/three-lucky-number",
      addEndPoint: "/three-winner-create",
      editEndPoint: "/three-winner-win-lose",
    },
    module: "ထွက်ဂဏာန်းများ"
  },

  {
    path: "winner/2d",
    component: Winner,
    props: {
      type: "MM 2D",
      winnerType: "MM",
      endPoint: "/two-winner-lists",
    },
    module: "ထီပေါက်သူများ"
  },
  {
    path: "winner/gold2d",
    component: Winner,
    props: {
      type: "Gold 2D",
      winnerType: "GL",
      endPoint: "/two-winner-lists",
    },
    module: "ထီပေါက်သူများ"
  },
  {
    path: "winner/dubai2d",
    component: Winner,
    props: {
      type: "Dubai 2D",
      winnerType: "DB",
      endPoint: "/two-winner-lists",
    },
    module: "ထီပေါက်သူများ"
  },
  {
    path: "winner/malay2d",
    component: Winner,
    props: {
      type: "Malay 2D",
      winnerType: "ML",
      endPoint: "/two-winner-lists",
    },
    module: "ထီပေါက်သူများ"
  },
  {
    path: "winner/3d",
    component: Winner,
    props: {
      type: "MM 3D",
      winnerType: "MM",
      endPoint: "/three-winner-lists",
    },
    module: "ထီပေါက်သူများ"
  },
  {
    path: "winner/dubai3d",
    component: Winner,
    props: {
      type: "Dubai 3D",
      winnerType: "DB",
      endPoint: "/three-winner-lists",
    },
    module: "ထီပေါက်သူများ"
  },
  {
    path: "winner/gold3d",
    component: Winner,
    props: {
      type: "Gold 3D",
      winnerType: "GL",
      endPoint: "/three-winner-lists",
    },
    module: "ထီပေါက်သူများ"
  },
  {
    path: "winner/btc2d",
    component: Winner,
    props: {
      type: "BTC 2D",
      winnerType: "BTC",
      endPoint: "/two-winner-lists",
    },
    module: "ထီပေါက်သူများ"
  },
  {
    path: "report/2d",
    component: Report,
    props: {
      type: "MM 2D",
      reportType: "MM",
      endPoint: "/two-user-count-sum-amount",
    },
    module : "မှတ်တမ်း (အနိုင်/အရှုံး)"
  },
  {
    path: "report/gold2d",
    component: Report,
    props: {
      type: "Gold 2D",
      reportType: "GL",
      endPoint: "/two-user-count-sum-amount",
    },
    module : "မှတ်တမ်း(အနိုင်/အရှုံး)"
  },
  {
    path: "report/dubai2d",
    component: Report,
    props: {
      type: "Dubai 2D",
      reportType: "DB",
      endPoint: "/two-user-count-sum-amount",
    },
    module : "မှတ်တမ်း(အနိုင်/အရှုံး)"
  },
  {
    path: "report/malay2d",
    component: Report,
    props: {
      type: "Malay 2D",
      reportType: "ML",
      endPoint: "/two-user-count-sum-amount",
    },
    module : "မှတ်တမ်း(အနိုင်/အရှုံး)"
  },
  {
    path: "report/3d",
    component: Report,
    props: {
      type: "MM 3D",
      reportType: "MM",
      endPoint: "/three-user-count-sum-amount",
    },
    module : "မှတ်တမ်း(အနိုင်/အရှုံး)"
  },
  {
    path: "report/dubai3d",
    component: Report,
    props: {
      type: "Dubai 3D",
      reportType: "DB",
      endPoint: "/three-user-count-sum-amount",
    },
    module : "မှတ်တမ်း(အနိုင်/အရှုံး)"
  },
  {
    path: "report/btc2d",
    component: Report,
    props: {
      type: "BTC 2D",
      reportType: "BTC",
      endPoint: "/two-user-count-sum-amount",
    },
    module : "မှတ်တမ်း(အနိုင်/အရှုံး)"
  },
  {
    path: "report/gold3d",
    component: Report,
    props: {
      type: "Gold 3D",
      reportType: "GL",
      endPoint: "/three-user-count-sum-amount",
    },
    module : "မှတ်တမ်း(အနိုင်/အရှုံး)"
  },
  {
    path: "profit/senior",
    component: ProfitLoss,
    props: {},
    module : "အရှုံး/အမြတ်စာရင်းများ"
  },

  {
    path: "two_lucky_draws/2d",
    component: Bets,
    props: {
      type: "MM 2D",
      betType: "MM",
      endPoint: "/user-betting-history",
    },
    module : "ထိုးထားသောစာရင်းများ"
  },
  {
    path: "two_lucky_draws/gold2d",
    component: Bets,
    props: {
      type: "Gold 2D",
      betType: "GL",
      endPoint: "/user-betting-history",
    },
    module : "ထိုးထားသောစာရင်းများ"
  },
  {
    path: "two_lucky_draws/btc2d",
    component: Bets,
    props: {
      type: "BTC 2D",
      betType: "BTC",
      endPoint: "/user-betting-history",
    },
    module : "ထိုးထားသောစာရင်းများ"
  },
  {
    path: "two_lucky_draws/dubai2d",
    component: Bets,
    props: {
      type: "Dubai 2D",
      betType: "DB",
      endPoint: "/user-betting-history",
    },
    module : "ထိုးထားသောစာရင်းများ"
  },
  {
    path: "two_lucky_draws/malay2d",
    component: Bets,
    props: {
      type: "Malay 2D",
      betType: "ML",
      endPoint: "/user-betting-history",
    },
    module : "ထိုးထားသောစာရင်းများ"
  },
  {
    path: "three_lucky_draws/3d",
    component: Bets,
    props: {
      type: "MM 3D",
      betType: "MM",
      endPoint: "/three-user-betting-history",
    },
    module : "ထိုးထားသောစာရင်းများ"
  },
  {
    path: "three_lucky_draws/dubai3d",
    component: Bets,
    props: {
      type: "Dubai 3D",
      betType: "DB",
      endPoint: "/three-user-betting-history",
    },
    module : "ထိုးထားသောစာရင်းများ"
  },
  {
    path: "three_lucky_draws/gold3d",
    component: Bets,
    props: {
      type: "Gold 3D",
      betType: "GL",
      endPoint: "/three-user-betting-history",
    },
    module : "ထိုးထားသောစာရင်းများ"
  },
];

