import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { postMethodService } from '../services';
import { CHANGE_PASSWORD_API, LOGIN_API } from '../services/constants';
import { useNavigate } from 'react-router-dom';
import { HandleLogout } from '../utils/Helper';
import CusInput from '../utils/CusInput';
import SubmitBtn from '../utils/SubmitBtn';

const ChangePassword = () => {

    const [isLoading,setIsLoading] = useState(false);
    const [oldPass,setOldPass] = useState("");
    const [newPass,setNewPass] = useState("");
    const [conPass,setConPass] = useState("");
    const [oldPasswordError,setOldPasswordError] = useState("");
    const [newPasswordError,setNewPasswordError] = useState("");
    const [conPasswordError,setConPasswordError] = useState("");
    const navigate = useNavigate();


    const changePassHandler = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const userData = {
            old_password: oldPass,
            password: newPass,
            password_confirmation: conPass
        };
        postMethodService(CHANGE_PASSWORD_API, userData, true)
        .then(res => {
            setIsLoading(false);
            if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
              HandleLogout(navigate);
            };
            if(res?.status === "success"){
                const getToken = localStorage.getItem("one_star_a");
                const delay = setTimeout(()=>{
                    if(getToken){
                        localStorage.removeItem("one_star_a");
                        setOldPass("");
                        setNewPass("");
                        setConPass("");
                        navigate('/login');
                    };
                  },1000);
      
                  return ()=> {
                      clearTimeout(delay);
                  };
            };
        });
    };

    const oldPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setOldPass(e.target.value);
        setOldPasswordError("");
    };

    const newPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setNewPass(e.target.value);
        setNewPasswordError("");
    };

    const conPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setConPass(e.target.value);
        setConPasswordError("");
    };

  return (
    <>
       
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <h4 className="card-title">
                                Change password
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-6">
                            <form onSubmit={changePassHandler}>
                                <CusInput title="Old password" type="password" error={oldPasswordError} tabIndex={0} placeholder="********" name="oldPassowrd" value={oldPass} event={oldPasswordHandler}/>
                                <CusInput title="New password" type="password" error={newPasswordError} tabIndex={1} placeholder="********" name="newPassowrd" value={newPass} event={newPasswordHandler}/>
                                <CusInput title="Confirm password" type="password" error={conPasswordError} tabIndex={2} placeholder="********" name="conPassowrd" value={conPass} event={conPasswordHandler}/>
                                <div className="d-grid">
                                    <SubmitBtn type="submit" isLoading={isLoading} name='Submit'/>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        
        <ToastContainer />
    </>
  )
}

export default ChangePassword
