import React from 'react';
import { Navigate } from 'react-router-dom';
import { decodeData } from '../utils/Helper';

const Protect = ({userInfo,children}: any) => {
  const token = localStorage.getItem('one_star_a');
  if(token){
    return children;
  }else{
    return <Navigate to='/login' />
  };
};

export default Protect
