import React, { useEffect, useState, useCallback } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { getMethodService, postMethodService } from '../services';
import { CHANGE_PASSWORD_API, LOGIN_API, WINNER_API } from '../services/constants';
import { useNavigate } from 'react-router-dom';
import { HandleLogout, amountCommasSparated } from '../utils/Helper';
import TableScroll from '../utils/TableScroll';
import TableLoading from '../utils/TableLoading';
import { useQuery } from '@tanstack/react-query';
import { stateObjType } from '../types';
import { DateRangePickerComponent } from '../utils/DateRange';
import { momentDateFormat } from '../services/helper';

const Winner = ({type,winnerType,endPoint}:any) => {
  const [isLoading,setIsLoading] = useState(false);
  const [winners,setWinners] = useState([]);
  const [refreshKey, setRefreshKey] = useState(0);
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const handleChangeValueDateRangePicker = useCallback(
    (start: any, end: any, label: any) => {
      setStartDate(start.format("YYYY-MM-DD"));
      setEndDate(end.format("YYYY-MM-DD"));
    },
    []
  );
  const fetchWinner = () =>
    getMethodService(`${WINNER_API}${endPoint}?type=${winnerType}&start_date=${startDate}&end_date=${endDate}`);  
    const winnerQuery = useQuery({ queryKey: [type,winnerType,refreshKey , startDate,endDate], queryFn: fetchWinner, refetchOnWindowFocus: false});

  useEffect(() => {
    if(winnerQuery){
        setIsLoading(winnerQuery.isLoading);
        if(winnerQuery?.data === 'Unauthenticated.' || winnerQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
            HandleLogout(navigate);
        };
        if(winnerQuery?.data?.status === "success"){
            setWinners(winnerQuery?.data?.data);
        };
    };
}, [winnerQuery]);

  return (
    <>
        {/* <Template title={`${type} Winner Lists`}> */}
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <h4 className="card-title">
                                Winner
                            </h4>
                        </div>
                        <div className="col-sm-7 d-flex justify-content-end">
                            <DateRangePickerComponent startDate={startDate} endDate={endDate} onChange={handleChangeValueDateRangePicker}/>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white w-100 table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                <th className="p-2 text-center text-light">No</th>
                                                <th className="p-2 text-center text-light">Name</th>
                                                <th className="p-2 text-center text-light">{
                                                    (type === "MM 3D" || type === "Dubai 3D") ? '3D' : '2D'
                                                }</th>
                                                <th className="p-2 text-center text-light">Time</th>
                                                <th className="p-2 text-center text-light">Amount</th>
                                                {
                                                   (type === "MM 3D" || type === "Dubai 3D") &&
                                                   <th className="p-2 text-center text-light">Status</th>
                                                }
                                                <th className="p-2 text-center text-light">Bingo</th>
                                                <th className="p-2 text-center text-light">Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && <TableLoading col={7} />}
                                            {
                                                winners?.length > 0 ?
                                                winners?.map((res:stateObjType,i: number) => 
                                                <tr style={{verticalAlign: 'middle'}} key={res?.id}>
                                                    <td className="p-2 text-center text-dark">{i + 1}</td>
                                                    <td className="p-2 text-center text-dark">{res?.user_name}</td>
                                                    {
                                                        (type === "MM 3D" || type === "Dubai 3D") ? 
                                                        <td className="p-2 text-center text-dark">{res?.bet_no}</td> : 
                                                        <td className="p-2 text-center text-dark">{res?.number}</td>
                                                    }
                                                    <td className="p-2 text-center text-dark">
                                                        {
                                                            type === "MM 2D" &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>12:01 PM</span>}
                                                                {res?.time === '2' && <span className='badge bg-danger'>04:30 PM</span>}
                                                            </>
                                                        }
                                                        {
                                                            type === "MM 3D" &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>၁၆ ရက်</span>}
                                                                {res?.time === '2' && <span className='badge bg-danger'>၁ ရက်</span>}
                                                            </>
                                                        }
                                                        {
                                                            type === "Malay 2D" &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>10:00 AM</span>}
                                                                {res?.time === '2' && <span className='badge bg-danger'>12:00 PM</span>}
                                                                {res?.time === '3' && <span className='badge bg-primary'>02:00 PM</span>}
                                                                {res?.time === '4' && <span className='badge bg-warning'>04:00 PM</span>}
                                                                {res?.time === '5' && <span className='badge bg-secondary'>06:00 PM</span>}
                                                                {res?.time === '6' && <span className='badge bg-success'>08:00 PM</span>}
                                                            </>
                                                        }
                                                        {
                                                            winnerType === "Dubai 2D" &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>11:00 AM</span>}
                                                                {res?.time === '2' && <span className='badge bg-danger'>01:00 PM</span>}
                                                                {res?.time === '3' && <span className='badge bg-primary'>03:00 PM</span>}
                                                                {res?.time === '4' && <span className='badge bg-warning'>05:00 PM</span>}
                                                                {res?.time === '5' && <span className='badge bg-secondary'>07:00 PM</span>}
                                                                {res?.time === '6' && <span className='badge bg-success'>09:00 PM</span>}
                                                            </>
                                                        }
                                                        {
                                                            winnerType === "Gold 2D" &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>09:30 AM</span>}
                                                                {res?.time === '2' && <span className='badge bg-danger'>12:00 PM</span>}
                                                                {res?.time === '3' && <span className='badge bg-primary'>02:00 PM</span>}
                                                                {res?.time === '4' && <span className='badge bg-warning'>04:30 PM</span>}
                                                                {res?.time === '5' && <span className='badge bg-success'>08:00 PM</span>}
                                                            </>
                                                        }
                                                        {
                                                            winnerType === "Dubai 3D" &&
                                                            <>
                                                                {res?.time === '1' && <span className='badge bg-info'>09:00 PM</span>}
                                                            </>
                                                        }
                                                    </td>
                                                    <td className="p-2 text-end text-dark">{amountCommasSparated(res?.bet_amount)}</td>
                                                    {
                                                        (type === "MM 3D" || type === "Dubai 3D") &&
                                                        <td className="p-2 text-center text-dark">
                                                            <span className={`badge bg-${res?.status === 'Full'? 'success':'info'}`}>{res?.status}</span>
                                                        </td>
                                                    }
                                                    <td className="p-2 text-end text-dark">{amountCommasSparated(res?.win_amount)}</td>
                                                    <td className="p-2 text-center text-dark">{momentDateFormat(res?.created_at)}</td>
                                                </tr>
                                                )
                                                
                                                :
                                                <tr>
                                                    <td className='text-center' colSpan={7}>No found data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
        {/* </Template> */}
        <ToastContainer />
    </>
  )
}

export default Winner
