import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getMethodService } from '../services';
import { useQuery } from '@tanstack/react-query';
import { AUTH_INFO_API } from '../services/constants';
import { HandleLogout, decodeData } from '../utils/Helper';
import { useNavigate } from 'react-router-dom';
import { stateObjType } from '../types';
import { userHandler } from '../store/slices/userInfoSlice';
const userImg = require('../assets/images/icons/img6.jpg');
const sidebarLogo = require('../assets/images/logo/dashboard_dark_logo.png')

const Header = () => {

  const [userInfo, setUserInfo] = useState<stateObjType>([]);
  const {value} = useSelector((state:any) => state.unitController);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user_info } = useSelector((state: any) => state.userInfo);


  // const fetchUserInfo = () => getMethodService(AUTH_INFO_API);
  // const userInfoQuery = useQuery({ queryKey: ['userinfo', value], queryFn: fetchUserInfo,refetchOnWindowFocus: false});

  // useEffect(()=>{
  //   if(userInfoQuery){
  //       if(userInfoQuery?.data === 'Unauthenticated.' || userInfoQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
  //           HandleLogout(navigate);
  //       };
  //       setUserInfo(userInfoQuery?.data?.data);
  //       dispatch(userHandler(userInfoQuery?.data?.data));
  //   };
  // },[userInfoQuery]);

  return (
    <header id="header" className="navbar navbar-expand-lg navbar-fixed navbar-height navbar-container navbar-bordered bg-white">
      <div className="navbar-nav-wrap">

      <a className="navbar-brand" href="#" aria-label="Front">
        <img className="rounded-circle" src={sidebarLogo} alt="Logo" data-hs-theme-appearance="default" style={{width: '50px',border: '2px solid #FFF',boxShadow: '0 5px 10px 0 rgba(43, 43, 43, .2)'}}/>
      </a>

      <div className="navbar-nav-wrap-content-start">
        <button type="button" className="js-navbar-vertical-aside-toggle-invoker navbar-aside-toggler">
          <i className="bi-arrow-bar-left navbar-toggler-short-align" data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Collapse"></i>
          <i className="bi-arrow-bar-right navbar-toggler-full-align" data-bs-template='<div className="tooltip d-none d-md-block" role="tooltip"><div className="arrow"></div><div className="tooltip-inner"></div></div>' data-bs-toggle="tooltip" data-bs-placement="right" title="Expand"></i>
        </button>
      </div>

        <div className="navbar-nav-wrap-content-end">
          <ul className="navbar-nav">
            <li className="nav-item">
              <div className="dropdown">
                <div className='d-flex align-items-center'>
                <p style={{"marginRight" : "48px", "marginTop" : "18px", "fontSize" : "16px"}} >{(user_info && user_info.role_id === 3) &&  user_info?.amount?.toLocaleString('en-US')}</p>
                <a className="navbar-dropdown-account-wrapper">
                  <div className="avatar avatar-sm avatar-circle">
                    <img className="avatar-img" src={userImg} alt="Image Description"/>
                    <span className="avatar-status avatar-sm-status avatar-status-success"></span>
                  </div>
                </a>
                
                </div>

                {/* <div className="dropdown-menu dropdown-menu-end navbar-dropdown-menu navbar-dropdown-menu-borderless navbar-dropdown-account" aria-labelledby="accountNavbarDropdown" style={{width: '16rem'}}>
                  <div className="dropdown-item-text">
                    <div className="d-flex align-items-center">
                      <div className="avatar avatar-sm avatar-circle">
                        <img className="avatar-img" src={userImg} alt="Image Description"/>
                      </div>
                      <div className="flex-grow-1 ms-3">
                        <h5 className="mb-0">{user_info && user_info.name}</h5>
                        <p className="card-text text-body">{user_info &&  user_info?.amount?.toLocaleString('en-US')}</p>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              
            </li>
          </ul>
        </div>
      </div>
    </header>
  )
}

export default Header
