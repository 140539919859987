import React, { useEffect, useState } from 'react'
import { UsersTypes, stateObjType } from '../types'
import Template from '../utils/Template'
import TableScroll from '../utils/TableScroll'
import TableLoading from '../utils/TableLoading'
import { getMethodService, postMethodService } from '../services'
import { useQuery } from '@tanstack/react-query'
import { HandleLogout, amountCommasSparated } from '../utils/Helper'
import { useNavigate } from 'react-router-dom'
import { DOWNLINE_CHANGE_PASSWORD_API, SENIORS_EDIT_API, SENIOR_BALANCE_TRANS_API, USERS_API, ADMIN_BALANCE_TRANS_API } from '../services/constants'
import { ToastContainer } from 'react-toastify'
import ModalBox from '../utils/ModalBox';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import Pagi from '../utils/Pagi';
import CusInput from '../utils/CusInput';

const Users = ({title,type,endPoint}:UsersTypes) => {
  const [isLoading,setIsLoading] = useState(false);
  const [users,setUsers] = useState([]);
  const [createSeniorModal,setCreateShowSeniorModal] = useState(false);
  const [showSeniorEditModal,setShowSeniorEditModal] = useState(false);
  const [showAdminEditModal,setShowAdminEditModal] = useState(false);

  const [showChangePasswordModal,setShowChangePasswordModal] = useState(false);
  const [walletModal,setWalletModal] = useState(false);
  const [editData,setEditData] = useState<any>(null);

  const [ban,setBan] = useState<any>(false);
  const [twoLimit,setTwoLimit] = useState<any>(0);
  const [goldLimit,setGoldLimit] = useState<any>(0);
  const [malayLimit,setMalayLimit] = useState<any>(0);
  const [dubaiLimit,setDubaiLimit] = useState<any>(0);
  const [shanLimit,setShanLimit] = useState<any>(0);
  const [threeLimit,setThreeLimit] = useState<any>(0);
  const [dubaiThreeLimit,setDubaiThreeLimit] = useState<any>(0);
  const [btcLimit,setBtcLimit] = useState<any>(0);
  const [goldThreeLimit,setGoldThreeLimit] = useState<any>(0);
  const [btcPercent,setBtcPercent] = useState<any>(0);
  const [goldThreePercent,setGoldThreePercent] = useState<any>(0);

  const [twoPercent,setTwoPercent] = useState<any>(0);
  const [goldPercent,setGoldPercent] = useState<any>(0);
  const [malayPercent,setMalayPercent] = useState<any>(0);
  const [dubaiPercent,setDubaiPercent] = useState<any>(0);
  const [threePercent,setThreePercent] = useState<any>(0);
  const [dubaiThreePercent,setDubaiThreePercent] = useState<any>(0);
//   const [shanPercent,setShanPercent] = useState<any>(0);
  const [amount,setAmount] = useState<any>(0);

  const [newPass,setNewPass] = useState("");
  const [conPass,setConPass] = useState("");
  const [newPasswordError,setNewPasswordError] = useState("");
  const [conPasswordError,setConPasswordError] = useState("");
  const [searchName,setSearchName] = useState("");


  const [totalPage, setTotalPage] = useState(1);
  const [page, setPage] = useState(1);
  const [from, setFrom] = useState(1);
  const [refreshKey, setRefreshKey] = useState(0);

  const navigate = useNavigate();

  const fetchUsers = () => getMethodService(`${USERS_API}/${type}?username=${searchName}&sortDirection=asc`);
  const usersQuery = useQuery({ queryKey: [`${type}`, searchName ,refreshKey], queryFn: fetchUsers, refetchOnWindowFocus: false});

    useEffect(() => {
        if(usersQuery){
            setIsLoading(usersQuery.isLoading);
            if(usersQuery?.data === 'Unauthenticated.' || usersQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(usersQuery?.data?.status === "success"){
                setUsers(usersQuery?.data?.data);

                if(usersQuery?.data?.meta){
                    if(usersQuery?.data?.meta?.current_page > 0){
                        if(usersQuery?.data?.meta?.per_page && usersQuery?.data?.meta?.current_page){
                            setFrom((usersQuery?.data?.meta.per_page * (usersQuery?.data?.meta?.current_page - 1)) + 1 );
                        };
                    };
                    setTotalPage(Math.ceil(usersQuery?.data?.meta?.total / usersQuery?.data?.meta?.per_page));
                };
            };
        };
    }, [usersQuery]);

    useEffect(()=>{
        setBan(editData?.banned_till ?? "");
        setTwoLimit(editData?.allowed_amount);
        setGoldLimit(editData?.allowed_amount_gold);
        setDubaiLimit(editData?.allowed_amount_dubai);
        setMalayLimit(editData?.allowed_amount_malay);
        setThreeLimit(editData?.allowed_amount_three);
        setDubaiThreeLimit(editData?.allowed_amount_dubai_three)
        setShanLimit(editData?.allowed_amount_shan);
        setTwoPercent(editData?.two_percentage);
        setTwoPercent(editData?.two_percentage);
        
        setBtcLimit(editData?.allowed_amount_btc);
        setBtcPercent(editData?.btc_percentage);
        setGoldThreePercent(editData?.gold_three_percentage);
        setGoldThreeLimit(editData?.allowed_amount_gold_three);
        
        setGoldPercent(editData?.gold_percentage);
        setDubaiPercent(editData?.dubai_percentage);
        setMalayPercent(editData?.malay_percentage);
        setThreePercent(editData?.three_percentage);
        setDubaiThreePercent(editData?.dubai_three_percentage);
        // setShanPercent(editData?.shan_percentage);
        if(editData?.statusType === "withdrawal"){
            setAmount(editData?.amount);
        };
    },[editData]);

    const seniorEditSubmitHandler = (e:React.FormEvent<HTMLFormElement>) => {
        const data = {
            allowed_amount: twoLimit,
            allowed_amount_gold: goldLimit,
            allowed_amount_dubai: dubaiLimit,
            allowed_amount_malay: malayLimit,
            allowed_amount_three: threeLimit,
            allowed_amount_dubai_three: dubaiThreeLimit,
            allowed_amount_shan: shanLimit,
            two_percentage: twoPercent,
            gold_percentage: goldPercent,
            dubai_percentage: dubaiPercent,
            malay_percentage: malayPercent,
            three_percentage: threePercent,
            dubai_three_percentage: dubaiThreePercent,
            allowed_amount_btc: btcLimit,
            allowed_amount_gold_three: goldThreeLimit,
            btc_percentage:btcPercent,
            gold_three_percentage:goldThreePercent,
            // shan_percentage: shanPercent,
            banned_till: ban,
        };

        if(editData){
            setIsLoading(true);
            postMethodService(
                SENIORS_EDIT_API(editData?.id),
                data,true
            )
            .then(res => {
                setIsLoading(false);
                if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                    HandleLogout(navigate);
                };
                if(res?.status === "success"){
                    setShowSeniorEditModal(false);
                    setRefreshKey(prevKey => prevKey + 1);
                };
            });
        };   
    };

    const adminEditSubmitHandler = (e:React.FormEvent<HTMLFormElement>) => {
        const data = {
            allowed_amount: twoLimit,
            allowed_amount_gold: goldLimit,
            allowed_amount_dubai: dubaiLimit,
            allowed_amount_malay: malayLimit,
            allowed_amount_three: threeLimit,
            allowed_amount_dubai_three: dubaiThreeLimit,
            allowed_amount_shan: shanLimit,
            two_percentage: twoPercent,
            gold_percentage: goldPercent,
            dubai_percentage: dubaiPercent,
            malay_percentage: malayPercent,
            three_percentage: threePercent,
            dubai_three_percentage: dubaiThreePercent,
            // shan_percentage: shanPercent,
            banned_till: ban
        };

        if(editData){
            setIsLoading(true);
            postMethodService(
                SENIORS_EDIT_API(editData?.id),
                data,true
            )
            .then(res => {
                setIsLoading(false);
                if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                    HandleLogout(navigate);
                };
                if(res?.status === "success"){
                    setShowSeniorEditModal(false);
                    setRefreshKey(prevKey => prevKey + 1);
                };
            });
        };   
    };
    const seniorWalletSubmitHandler = (e:React.FormEvent<HTMLFormElement>) => {
        const data = {
            amount: amount,
            status: editData?.statusType
        };
        if(editData){
            
            setIsLoading(true);
            const callFunc = type === 'seniors' ? SENIOR_BALANCE_TRANS_API(editData?.id) : ADMIN_BALANCE_TRANS_API(editData?.id)
            postMethodService(
                callFunc,
                data,true
            )
            .then(res => {
                setIsLoading(false);
                if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                    HandleLogout(navigate);
                };
                if(res?.status === "success"){
                    setWalletModal(false);
                    setAmount(0)
                    setRefreshKey(prevKey => prevKey + 1);
                    window.location.reload()
                };
            });
        };   
    };

    const newPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setNewPass(e.target.value);
        setNewPasswordError("");
    };

    const conPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
        setConPass(e.target.value);
        setConPasswordError("");
    };
    
    const changePassHandler = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const userData = {
            password: newPass,
            password_confirmation: conPass
        };
        if(editData){
           
            setIsLoading(true);
            const updateId = type === 'seniors' ? editData?.user?.id : editData.id;
            console.log(updateId);
            postMethodService(DOWNLINE_CHANGE_PASSWORD_API(updateId), userData, true)
            .then(res => {
                setIsLoading(false);
                if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                    HandleLogout(navigate);
                };
                if(res?.status === "success"){
                    setShowChangePasswordModal(false);
                    setRefreshKey(prevKey => prevKey + 1);
                    setNewPass("");
                    setConPass("");
                    setNewPasswordError("");
                    setConPasswordError("");
                };
            });
        }
    }

  return (
    <>
      
            <div className="card mb-3">
                <div className="card-header">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-sm-5">
                            <h4 className="card-title mb-2 mb-sm-0">
                                {title} lists
                            </h4>
                        </div>
                        <div className="col-sm-5">
                        <input type='text' className='form-control' value={searchName} placeholder='Search Name' onChange={e => {setSearchName(e.target.value)}}/>

                        </div>
                        <div className="col-sm-2">
                            {
                                (type === "seniors") &&
                                <button className='btn btn-outline-primary float-end' onClick={()=> navigate('/users/create-senior')}>Create</button>
                            }
                            {
                                (type === "admins") &&
                                <button className='btn btn-outline-primary float-end' onClick={()=> navigate('/users/create-admin')}>Create</button>
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white w-100 table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                <th className="p-2 text-center text-light">No</th>
                                                {
                                                    type === "masters" &&
                                                    <th className="p-2 text-center text-light">Senior</th>
                                                }
                                                {
                                                    type === "agents" &&
                                                    <>
                                                        <th className="p-2 text-center text-light">Senior</th>
                                                        <th className="p-2 text-center text-light">Master</th>
                                                    </>
                                                }
                                                {
                                                    type === "users" &&
                                                    <>
                                                        <th className="p-2 text-center text-light">Senior</th>
                                                        <th className="p-2 text-center text-light">Master</th>
                                                        <th className="p-2 text-center text-light">Agent</th>
                                                    </>
                                                }
                                                <th className="p-2 text-center text-light">Name</th>
                                                <th className="p-2 text-center text-light">User Name</th>
                                                {
                                                    (type != "users" && type != "admins") &&
                                                    <>
                                                        <th className="p-2 text-center text-light">MM 2D Limit</th>
                                                        <th className="p-2 text-center text-light">Gold Limit</th>
                                                        <th className="p-2 text-center text-light">Dubai Limit</th>
                                                        <th className="p-2 text-center text-light">Malay Limit</th>
                                                        <th className="p-2 text-center text-light">MM 3D Limit</th>
                                                        <th className="p-2 text-center text-light">Dubai 3D Limit</th>
                                                        <th className="p-2 text-center text-light">Gold 3D Limit</th>
                                                        <th className="p-2 text-center text-light">BTC Limit</th>
                                                        <th className="p-2 text-center text-light">MM 2D %</th>
                                                        <th className="p-2 text-center text-light">Gold %</th>
                                                        <th className="p-2 text-center text-light">Dubai %</th>
                                                        <th className="p-2 text-center text-light">Malay %</th>
                                                        <th className="p-2 text-center text-light">MM 3D %</th>
                                                        <th className="p-2 text-center text-light">Dubai 3D %</th>
                                                        <th className="p-2 text-center text-light">BTC %</th>
                                                        <th className="p-2 text-center text-light">Gold 3D %</th>

                                                    </>
                                                }
                                                <th className="p-2 text-center text-light">Balance</th>
                                                <th className="p-2 text-center text-light">Registered Date</th>
                                                {
                                                    (type === "seniors" || type === "admins") &&
                                                    <>
                                                        <th className="p-2 text-center text-light">Ban status</th>
                                                        <th className="p-2 text-center text-light">Action</th>
                                                    </>
                                                }
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && <TableLoading col={22} />}
                                            {
                                                users.length > 0 ?
                                                users.map((user:stateObjType,i: number) =>
                                                    <tr style={{verticalAlign: 'middle'}} key={i}>
                                                        <td className="p-2 text-center text-dark">{i + 1}</td>
                                                        {
                                                            type === "masters" &&
                                                            <td className="p-2 text-center text-dark">{user?.seniors?.username ?? "-"}</td>
                                                        }
                                                        {
                                                            type === "agents" &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{user?.masters?.seniors?.username ?? "-"}</td>
                                                                <td className="p-2 text-center text-dark">{user?.masters?.username ?? "-"}</td>
                                                            </>
                                                        }
                                                        {
                                                            type === "users" &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{user?.user_agent?.masters?.seniors?.username ?? "-"}</td>
                                                                <td className="p-2 text-center text-dark">{user?.user_agent?.masters?.username ?? "-"}</td>
                                                                <td className="p-2 text-center text-dark">{user?.user_agent?.username ?? "-"}</td>
                                                            </>
                                                        }
                                                        <td className="p-2 text-center text-dark">{user?.name}</td>
                                                        <td className="p-2 text-center text-dark">{user?.username}</td>
                                                        {
                                                            (type != "users" &&type != "admins") &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.allowed_amount) ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.allowed_amount_gold) ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.allowed_amount_dubai) ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.allowed_amount_malay) ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.allowed_amount_three) ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.allowed_amount_dubai_three) ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.allowed_amount_gold_three) ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{amountCommasSparated(user?.allowed_amount_btc) ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{user?.two_percentage ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{user?.gold_percentage ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{user?.dubai_percentage ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{user?.malay_percentage ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{user?.three_percentage ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{user?.dubai_three_percentage ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{user?.btc_percentage ?? '-'}</td>
                                                                <td className="p-2 text-center text-dark">{user?.gold_three_percentage ?? '-'}</td>

                                                            </>
                                                        }
                                                        <td className="p-2 text-center text-dark">
                                                            <div>
                                                                {
                                                                   (type === "seniors" || type === "admins") &&
                                                                    <button type='button' className='btn btn-success px-2 py-1 rounded-circle' onClick={() => {
                                                                        setWalletModal(true);
                                                                        setEditData({statusType: "deposit",...user});
                                                                    }}>
                                                                        <i className="bi bi-plus"></i>
                                                                    </button>
                                                                }
                                                                <span className='mx-2 d-inline-block' style={{minWidth: '60px'}}>{amountCommasSparated(user?.amount)}</span>
                                                                {
                                                                   (type === "seniors" || type === "admins") &&
                                                                    <button type='button' className='btn btn-danger px-2 py-1 rounded-circle' onClick={() => {
                                                                        setWalletModal(true);

                                                                        setEditData({statusType: "withdrawal",...user});
                                                                    }}>
                                                                        <i className="bi bi-dash"></i>
                                                                    </button>
                                                                }
                                                            </div>
                                                        </td>
                                                        <td className="p-2 text-center text-dark">{user?.created_at}</td>

                                                        {
                                                            (type === "seniors" || type === "admins")  &&
                                                            <>
                                                                <td className="p-2 text-center text-dark">{user?.banned_till?  <span className='badge bg-danger'>{(Number(user?.banned_till) > 1 ?user?.banned_till+" Days": user?.banned_till+" Day")}</span> : '-'}</td>
                                                                <td className="p-2 text-center text-dark">
                                                                    <button className='btn btn-outline-success py-1' onClick={() => {
                                                                         setEditData(user);
                                                                        if (type === 'seniors') {
                                                                            setShowSeniorEditModal(true);
                                                                        }else {
                                                                            setShowAdminEditModal(true)
                                                                        }   
                                                                        
                                            
                                                                    }}>
                                                                        Edit
                                                                    </button>
                                                                    <button className='btn btn-outline-success py-1 ms-1' onClick={() => {
                                                                        setShowChangePasswordModal(true);
                                                                        setEditData(user);
                                                                        setNewPass("");
                                                                        setConPass("");
                                                                        setNewPasswordError("");
                                                                        setConPasswordError("");
                                                                    }}>
                                                                        Change password
                                                                    </button>
                                                                </td>
                                                            </>
                                                        }
                                                    </tr>
                                                )
                                                :
                                                <tr>
                                                    <td className='text-center' colSpan={22}>No found data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
                {totalPage > 1 && (
                    <div className="card-footer">
                        <div className="row justify-content-end">
                            <div>
                                <Pagi
                                    setIsLoading={setIsLoading}
                                    setPage={setPage}
                                    totalPage={totalPage}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
      <ToastContainer />
      {showSeniorEditModal && (
            <ModalBox
                title={`Update - ${editData?.name}`}
                onClose={() => setShowSeniorEditModal(false)}
                onSubmit={seniorEditSubmitHandler}
                isLoading={isLoading}
            >
                <Form>
                    <FormGroup>
                        <Label for="amount">MM 2D Limit</Label>
                        <Input type="number" value={twoLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setTwoLimit(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Gold 2D Limit</Label>
                        <Input type="number" value={goldLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setGoldLimit(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Dubai 2D Limit</Label>
                        <Input type="number" value={dubaiLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setDubaiLimit(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Malay 2D Limit</Label>
                        <Input type="number" value={malayLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setMalayLimit(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">MM 3D Limit</Label>
                        <Input type="number" value={threeLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setThreeLimit(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Dubai 3D Limit</Label>
                        <Input type="number" value={dubaiThreeLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setDubaiThreeLimit(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Gold 3D Limit</Label>
                        <Input type="number" value={goldThreeLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setGoldThreeLimit(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">BTC Limit</Label>
                        <Input type="number" value={btcLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setBtcLimit(e.target.value)}/>
                    </FormGroup>
                    
                    <FormGroup>
                        <Label for="amount">MM 2D %</Label>
                        <Input type="number" value={twoPercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setTwoPercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Gold 2D %</Label>
                        <Input type="number" value={goldPercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setGoldPercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Dubai 2D %</Label>
                        <Input type="number" value={dubaiPercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setDubaiPercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Malay 2D %</Label>
                        <Input type="number" value={malayPercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setMalayPercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">BTC 2D %</Label>
                        <Input type="number" value={btcPercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setBtcPercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">MM 3D %</Label>
                        <Input type="number" value={threePercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setThreePercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Dubai 3D %</Label>
                        <Input type="number" value={dubaiThreePercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setDubaiThreePercent(e.target.value)}/>
                    </FormGroup>
                    <FormGroup>
                        <Label for="amount">Gold 3D %</Label>
                        <Input type="number" value={goldThreePercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setGoldThreePercent(e.target.value)}/>
                    </FormGroup>
                    {/* <FormGroup>
                        <Label for="amount">Shan %</Label>
                        <Input type="number" value={shanPercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setShanPercent(e.target.value)}/>
                    </FormGroup> */}
                    <FormGroup>
                        <Label for="amount">Banned</Label>
                        <select className="form-control" onChange={e => setBan(e.target.value)} value={ban}>
                            <option value="">Choose ban type</option>
                            <option value={1}>Ban next 1 day</option>
                            <option value={3}>Ban next 3 day</option>
                            <option value={7}>Ban next 7 day</option>
                            <option value={14}>Ban next 14 day</option>
                            <option value={100}>Ban permanently</option>
                            <option value={0}>Unban</option>
                        </select>
                    </FormGroup>
                </Form>
            </ModalBox>
      )}
       {showAdminEditModal && (
            <ModalBox
                title={`Update - ${editData?.name}`}
                onClose={() => setShowAdminEditModal(false)}
                onSubmit={adminEditSubmitHandler}
                isLoading={isLoading}
            >
                <Form>
                    <FormGroup>
                        <Label for="amount">MM 2D Limit</Label>
                        <Input type="number" value={twoLimit} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setTwoLimit(e.target.value)}/>
                    </FormGroup>
                    
                    
                    {/* <FormGroup>
                        <Label for="amount">Shan %</Label>
                        <Input type="number" value={shanPercent} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>setShanPercent(e.target.value)}/>
                    </FormGroup> */}
                    
                </Form>
            </ModalBox>
      )}
      {
        walletModal && (
            <ModalBox
                title={`${type.charAt(0).toUpperCase() + type.slice(1).toLowerCase()} ${editData?.statusType}`}
                onClose={() => setWalletModal(false)}
                onSubmit={seniorWalletSubmitHandler}
                isLoading={isLoading}
            >
                <Form>
                    <FormGroup>
                        <Label for="amount">Amount</Label>
                        <Input type="number" value={amount} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{
                            e.target.value = e.target.value.replace('-', '');
                            setAmount(e.target.value);
                        }}/>
                    </FormGroup>
                </Form>
            </ModalBox>
        )
      }
      {
        showChangePasswordModal && (
            <ModalBox
                title= "Change password"
                onClose={() => setShowChangePasswordModal(false)}
                onSubmit={changePassHandler}
                isLoading={isLoading}
            >
                <form>
                    <CusInput title="New password" type="password" error={newPasswordError} tabIndex={1} placeholder="********" name="newPassowrd" value={newPass} event={newPasswordHandler}/>
                    <CusInput title="Confirm password" type="password" error={conPasswordError} tabIndex={2} placeholder="********" name="conPassowrd" value={conPass} event={conPasswordHandler}/>
                </form>
            </ModalBox>
        )
      }
    </>
  )
  
}

export default Users
