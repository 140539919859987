import React, { useEffect, useState } from 'react'
import Template from '../utils/Template'
import { ToastContainer } from 'react-toastify'
import { getMethodService, postMethodService } from '../services';
import { CAPTION_API, CAPTION_EDIT_API, CHANGE_PASSWORD_API, LOGIN_API } from '../services/constants';
import { useNavigate } from 'react-router-dom';
import { HandleLogout } from '../utils/Helper';
import TableScroll from '../utils/TableScroll';
import { useQuery } from '@tanstack/react-query';
import TableLoading from '../utils/TableLoading';
import { stateObjType } from '../types';
import ModalBox from '../utils/ModalBox';
import { Form, FormGroup, Input, Label } from 'reactstrap';
import Toggle from '../utils/Toggle';

const Caption = () => {
    const [isLoading,setIsLoading] = useState(false);
    const [caption,setCaption] = useState<any>([]);
    const [refreshKey, setRefreshKey] = useState(0);
    const [captionEditModal,setCaptionEditModal] = useState(false);
    const [editData,setEditData] = useState<any>(null);
    const [captionText,setCaptionText] = useState("");
    const navigate = useNavigate();
    const fetchCaption = () => getMethodService(`${CAPTION_API}?type=all&sortDirection=asc`);
    const captionQuery = useQuery({ queryKey: [refreshKey], queryFn: fetchCaption, refetchOnWindowFocus: false});

    useEffect(() => {
        if(captionQuery){
            setIsLoading(captionQuery.isLoading);
            if(captionQuery?.data === 'Unauthenticated.' || captionQuery?.data === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(captionQuery?.data?.status === "success"){
                setCaption(captionQuery?.data?.data);
            };
        };
    }, [captionQuery]);

    useEffect(()=>{
        setCaptionText(editData?.caption)
    },[editData])

    const captionEditHandler = (e:React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const data = {
            caption: captionText,
        };
        if(editData){
            postMethodService(CAPTION_EDIT_API(editData?.id),data, true)
            .then(res => {
                setIsLoading(false);
                if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
                HandleLogout(navigate);
                };
                if(res?.status === "success"){
                    setCaptionEditModal(false);
                    setRefreshKey(prevKey => prevKey + 1);
                };
                
            });
        }
    };

    const captionStatusToggleHandler = (status:any,id:number) => {
        postMethodService(CAPTION_EDIT_API(id), {status: `${status ? 'disable' : 'active'}`}, true)
        .then(res => {
            setIsLoading(false);
            if(res === 'Unauthenticated.' || res === 'E_UNAUTHORIZED_ACCESS: Unauthorized access'){
                HandleLogout(navigate);
            };
            if(res?.status === "success"){
                setRefreshKey(prev => prev + 1)
            };
        });
    };
   
  return (
    <>
        
            <div className="card mb-3 mb-lg-5">
                <div className="card-header">
                    <div className="row align-items-center">
                        <div className="col-sm-5">
                            <h4 className="card-title">
                                Caption
                            </h4>
                        </div>
                    </div>
                </div>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-12">
                            <TableScroll>
                                <div>
                                    <table className="table text-white w-100 table-bordered table-striped">
                                        <thead>
                                            <tr style={{backgroundColor: 'rgb(70 70 227)'}}>
                                                <th className="p-2 text-center text-light">No</th>
                                                <th className="p-2 text-center text-light">Caption</th>
                                                <th className="p-2 text-center text-light">Status</th>
                                                <th className="p-2 text-center text-light">Date</th>
                                                <th className="p-2 text-center text-light">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {isLoading && <TableLoading col={5} />}
                                            {
                                                caption.length > 0 ? 
                                                caption.map((cap:stateObjType,i: number) => 
                                                <tr style={{verticalAlign: 'middle'}} key={i}>
                                                    <td className="p-2 text-center text-dark">{i + 1}</td>
                                                    <td style={{whiteSpace: 'normal'}} className="p-2 text-center text-dark">
                                                        {cap?.caption}
                                                    </td>
                                                    <td className="p-2 text-center text-dark">
                                                        <Toggle id={cap?.id} status={cap?.status === 'active'? true: false} forId={`threeToggle${cap?.id}`} event={captionStatusToggleHandler} />
                                                    </td>
                                                    <td className="p-2 text-center text-dark">{cap?.updated_at}</td>
                                                    <td className="p-2 text-center text-dark">
                                                        <button className='btn btn-outline-success py-1' onClick={() => {
                                                            setCaptionEditModal(true);
                                                            setEditData(cap);
                                                        }}>
                                                            Edit
                                                        </button>
                                                    </td>
                                                </tr>
                                                )
                                                :
                                                <tr>
                                                    <td className='text-center' colSpan={17}>No found data</td>
                                                </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </TableScroll>
                        </div>
                    </div>
                </div>
            </div>
        <ToastContainer />
        {
        captionEditModal && (
            <ModalBox
                title="Edit Caption"
                onClose={() => setCaptionEditModal(false)}
                onSubmit={captionEditHandler}
                isLoading={isLoading}
            >
                <Form>
                    <FormGroup>
                        <Label for="">Caption</Label>
                        <Input type="textarea" rows="5" value={captionText} onChange={(e:React.ChangeEvent<HTMLInputElement>)=>{
                            setCaptionText(e.target.value);
                        }}/>
                    </FormGroup>
                </Form>
            </ModalBox>
        )
      }
    </>
  )
}

export default Caption
