import React, { useState } from 'react';
import CusInput from '../utils/CusInput';
import { postMethodService } from '../services';
import { LOGIN_API } from '../services/constants';
import { ToastContainer } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { HandleLogout, encodeData } from '../utils/Helper';
import SubmitBtn from '../utils/SubmitBtn';
const logo = require("../assets/images/logo/logo.png");
const bgImg = require('../assets/images/background/card-6.svg')

const Login = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [loginCode,setLoginCode] = useState("");
  const [loginPassword,setLoginPassword] = useState("");
  const [loginCodeError,setLoginCodeError] = useState("");
  const [loginPasswordError,setLoginPasswordError] = useState("");
  const navigate = useNavigate();

  const loginSubmitHandler = async (e:React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      setIsLoading(true);
      const data = {
         username: loginCode,
         password: loginPassword
      };
      postMethodService(LOGIN_API, data, true)
      .then(res => {
        setIsLoading(false);
        if(res === 'Unauthenticated.' || res === "E_UNAUTHORIZED_ACCESS: Unauthorized access"){
            HandleLogout(navigate);
        };
        if(res?.status === "success"){
            const saveInfo = {
              token: res?.data?.token
            };
            const bcode = encodeData(saveInfo);
            localStorage.setItem("one_star_a", bcode);
            const delay = setTimeout(()=>{
              setLoginCode("");
              setLoginPassword("");
              navigate('/');
              window.location.reload();
            },1000);

            return ()=> {
                clearTimeout(delay);
            }
        };
      })
     
  };

  const loginCodeHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
      setLoginCode(e.target.value);
      setLoginCodeError("");
  };

  const loginPasswordHandler = (e:React.ChangeEvent<HTMLInputElement>) => {
      setLoginPassword(e.target.value);
      setLoginPasswordError("");
  };
  

  return (
    <>
      <main id="content" role="main" className="">
        <div className="position-fixed top-0 end-0 start-0 bg-img-start" style={{height: '32rem', backgroundImage: `url(${bgImg.default})`}}>
            <div className="shape shape-bottom zi-1">
              <svg preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 1921 273">
                <polygon fill="#fff" points="0,273 1921,273 1921,0 " />
              </svg>
            </div>
        </div>
        <div className="container py-5 py-sm-7">
          <div className="d-flex justify-content-center mb-5">
            <img className="zi-2 rounded-circle" src={logo} alt="Image Description" style={{width: '8rem', border: '2px solid #FFF',boxShadow: '0 5px 10px 0 rgba(43, 43, 43, .2)'}}/>
          </div>

          <div className="mx-auto" style={{maxWidth: '30rem'}}>
          
            <div className="card card-lg mb-5">
              <div className="card-body">
            
                <form onSubmit={loginSubmitHandler}>
                  <div className="text-center">
                    <div className="mb-5">
                      <h1 className="display-5">Nex2d3d | Admin Login</h1>
                    </div>
                  </div>
                  <CusInput title="Username" type="text" error={loginCodeError} tabIndex={1} placeholder='Code' name="code" value={loginCode} event={loginCodeHandler}/>
                  <CusInput title="Password" type="password" error={loginPasswordError} tabIndex={0} placeholder='8+ characters required' name="password" value={loginPassword} event={loginPasswordHandler}/>
                  <div className="form-check mb-4">
                    <input className="form-check-input" type="checkbox" value="" id="termsCheckbox" />
                    <label className="form-check-label" htmlFor="termsCheckbox">
                      Remember me
                    </label>
                  </div>

                  <div className="d-grid">
                      <SubmitBtn type="submit" isLoading={isLoading} name='Sign in'/>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ToastContainer />
    </>
  )
}

export default Login
